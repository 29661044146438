import React from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Sub_Navigation from "../components/common/navigation/sub_navigation"
import Policy from "../components/sections/terms"


const TermsPage = () => (
    <Layout>
    <SEO title="Terms" />
    <Sub_Navigation />
    <Policy />
  </Layout>
)


export default TermsPage