import React from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="start">
    <StyledContainer>
      <SectionTitle>Arco Innovation's Terms & Conditions of Sale</SectionTitle>
          

      <FeaturesGrid>
        <FeatureItem id="general">
          <FeatureText>
          These Terms and Conditions of Sale (“Terms and Conditions”) shall govern each sales transaction for products and/or services between Arco Innovation ("Arco") and Buyer through this eCommerce website ("Site").
          By purchasing a Product or Service, you accept and agree to be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, do not purchase Products or Services from the Site.
          Any inconsistent terms of Buyer, wherever set forth and whether oral or written, shall not be binding upon Arco Innovation unless agreed to by Arco Innovation in writing. No waiver of these Terms and Conditions or any special terms relating to an order shall be binding upon Arco Innovation unless agreed to in writing by Arco Innovation. These Terms and Conditions are subject to change by Arco Innovation with respect to any products or services supplied by Arco Innovation in the future. Most current version of these terms are published in this Site.
          <br></br>
          <br></br>
          <strong>1. ORDER ELIGIBILITY.</strong> To place your order, you must have a valid billing and shipping address within a country that can be selected as part of the checkout process on the Site (the “Region”). We make no promise that Products or Services available on the Site are appropriate or available for use in locations outside the Region.
          <br></br>
          <br></br>
          <strong>2. PRODUCT AVAILABILITY AND INFORMATION.</strong> Details of the Products and Services we offer are set out on the Site. All features, content, specifications, Products, and prices of Products and Services described or depicted on the Site are subject to change at any time without notice. We attempt to ensure that information on the Site is complete, accurate, and current. Despite our efforts, the information on the Site may occasionally be inaccurate, incomplete, or out of date. We make no representation as to the completeness, availability, accuracy or currency of any information on the Site. For example, Products or Services included on the Site may be unavailable, may have different attributes than those listed, or may actually carry a different price than that stated on the Site.
          <br></br>
          <br></br>
          We make reasonable efforts to publish information and display Product images accurately, but we cannot guarantee that the image you see on your monitor or mobile device will exactly match the Product. Certain weights, measures and similar descriptions are approximate and are provided for convenience purposes only. Packaging may vary from that shown.
          <br></br>
          <br></br>
          <strong>3. PRICES.</strong> Prices published on the Site do not include shipping, handling, payment processing fees nor any sales, use, excise, value added, or other similar tax. These charges, if applicable, will be shown at the check-out page and invoice.  Each item in your shopping cart is shown at the current price. Prices are subject to change at any time without notice . SPECIALS. From time-to-time, Arco will offer promotions, special sales and discounted items. Inventory is limited. Specials are first come, first served; no rain checks available.
          <br></br>
          <br></br>
          <strong>4. MONITORING AND NOTIFICATION SERVICE.</strong> Arco Innovation’s monitoring and notification service is provided “free of charge” to customers who purchase Arco’s wireless hardware. The free service has limits described in the Monitoring and Notification Service page: <a href="www.arcoinnovation.com/service"> www.arcoinnovation.com/service</a>. Additional limits or quota can be purchased from the Site.
          <br></br>
          <br></br>
          Arco's Products and Services rely on or inter-operate with third-party products and services, including, without limitation, data storage services, communications technologies, IoT platforms, third party app stores, and internet and mobile operators (collectively, “Third-Party Materials”). These Third-party Materials are beyond Arco's control, but their operation may impact, or be impacted by, the use and reliability of the Arco's Services and Products. You acknowledge that:
          <br></br>
          <br></br>
          a. the use and availability of the Products and Services is dependent on third-party product vendors and service providers;
          <br></br>
          <br></br>
          b. these Third-Party Materials may not operate in a reliable manner 100% of the time and they may impact on the way that the Arco Services operate; and
          <br></br>
          <br></br>
          c. except as otherwise required by law, Arco is not responsible for damages and losses due to the operation of these Third-Party Materials.
          <br></br>
          <br></br>
          Additionally, certain items of independent, third-party code may be utilized in connection with the Products or Services that may be subject to open-source licenses (“Open-source Software”). The Open-source Software is licensed under the terms of the license that accompanies such Open-source Software. Nothing in the Terms limits your rights under, or grants you rights that supersede, the terms and conditions of any applicable license for such Open-source Software. Please see Open Source Software Disclosure for Arco's Products and Services for more information regarding the Open Source Software utilized in connection with our Products and Services.
          <br></br>
          <br></br>
          We offer API's for easy integration to Third-Party services for example, IoT automation platforms. Your dealings or correspondence with third parties and your use of or interaction with any Third-Party Content are solely between you and the third party.
          <br></br>
          Arco does not control or endorse, and makes no representations or warranties regarding, any Third-Party Content, and your access to and use of such Third-Party Content is at your own risk.
          <br></br>
          <br></br>
          Some Third-Party Materials and Third-Party Content have their own separate terms of use or terms of service as well as their own privacy policies. If applicable, you are subject to, and will comply with, such terms and policies. To the extent that such other terms and policies are less restrictive than a provision in these Terms, the provision in these Terms will supersede.
          <br></br>
          <br></br>
          We do not warrant or endorse, and will have no responsibility with respect to: (i) products or services that you have been offered or that you obtain from a third party that relate to the Products or Services, even if accessed through a hyperlink or other method using the Services, (ii) any Third-Party Materials or Third-Party Content, or (iii) any data or other information you may provide to any third party. You are responsible for obtaining and maintaining any computer hardware, equipment, network services and connectivity, telecommunications services and other products and services necessary to access and use the Products and Services.
          <br></br>
          <br></br>
          <strong>5. ORDER PROCESS AND ACCEPTANCE.</strong> You may only purchase Products and Services for personal use by either yourself or your intended recipient of the Products. The Products and Services are not authorized for resale. We may place a limit on the quantities that may be purchased per order, per account, per credit card, per person, or per household. We reserve the right, without prior notice, to refuse service to any customer or reject any order at any time and refund any money you have paid for such order.
          <br></br>
          <br></br>
          <strong>6. ORDER PROCESS AND ACCEPTANCE.</strong> You will have the opportunity to review and confirm your order, including delivery address, payment method, and other details of your order. All orders are subject to Arco Innovation’s approval and acceptance. A written acknowledgement sent to Buyer by email with confirmation of payment shall constitute Arco Innovation’s acceptance.
          <br></br>
          <br></br>
          We reserve the right to refuse or cancel any order prior to delivery. For example, if there are errors on the Site or made in connection with your order, inaccuracies in Product or pricing information or Product availability, we reserve the right to correct the error and charge you the correct price or cancel your order. We also may require verification of information prior to the acceptance and/or shipment of any order. We will contact you if any portion of your order is cancelled or if additional information is required to accept your order.
          <br></br>
          <br></br>
          <strong>7. CANCELLATION.</strong> No order accepted by Arco Innovation will be subject to cancellation, termination, suspension, change, reduction, cutback or any modifications by Buyer except with Arco Innovation's prior written consent, which may be subject to fees as Arco Innovation determines.
          <br></br>
          <br></br>
          <strong>8. DELIVERY.</strong> You will pay all shipping and handling charges specified during the ordering process. Shipping and handling charges are reimbursement for the costs we incur in the processing, handling, packing, shipping and delivery of your order.
          <br></br>
          <br></br>
          Occasionally, the manufacture or distribution of a certain Product or Service may be delayed. In such event, we will make reasonable efforts to notify you of the delay and keep you informed of the revised delivery schedule.
          <br></br>
          <br></br>
          Arco Innovation’s delivery terms are F.O.B Arco Innovation’s warehouse. Separate charges for shipping and handling will be shown on the invoice(s). Arco Innovation will ship with the carrier of their choice and use their standard shipping rates.
          <br></br>
          <br></br>
          Shipment schedules are approximate only and are as accurate as present conditions permit. Arco Innovation assumes no responsibility or liability for failure or delay in making delivery or otherwise performing hereunder when such failure or delay is due to any cause beyond its control and without its fault or negligence. Delivery to a common carrier shall constitute delivery to Buyer and all risk of loss or damage in transit shall be borne by Buyer. If because of Buyer's inability to take delivery, the materials or equipment are not shipped or returned, Arco Innovation may have them stored for Buyer at Buyer's expense, risk and account, and for all other purposes they shall be considered "shipped." Arco Innovation may make partial deliveries which Buyer shall accept and pay for at the prices quoted by Arco Innovation in writing. If any part of the order is not delivered by Arco Innovation or is not in accordance with the order, the remainder of the order and Buyer's obligation thereunder shall not be affected.
          <br></br>
          <br></br>
          <strong>9. EXCUSABLE DELAY, INABILITY TO SUPPLY AND/OR LIMITED SUPPLY.</strong> Neither Arco Innovation nor Buyer shall be liable for failure to perform (except for payment by Buyer for Product(s) received) if performance is prevented, restricted, or interfered with by reason of fire or earthquake or other casualty or accident; inability to procure materials, power, or supplies; war, terrorism, or other violence; any law, order, proclamation, regulation, ordinance, demand, or requirement of any government agency, court, or intergovernmental body; situation in which Arco Innovation has insufficient capacity to manufacture or deliver forecasted demand across any and all product lines; environmental, health, safety, or regulatory reason as reasonably determined by Arco Innovation; or any other event or condition whatsoever beyond its reasonable control. A party, when so affected and upon giving notice, shall be excused from such performance to the extent of such prevention, restriction, or interference; provided that the affected party shall use its reasonable efforts to avoid or remove such reason for non-performance and shall promptly resume performance if and when such reason is remedied. In the event of back-order or other limited supply or availability of product(s) for such reason or any other reason, Arco Innovation reserves the right, in its sole discretion and in a manner that it determines to be fair and reasonable under the circumstances, to allocate production capacity related to and/or supply of product(s).
          <br></br>
          <br></br>
          <strong>10. PACKAGING.</strong> All items sold hereunder shall be packed and shipped in accordance with Arco Innovation's best judgment.
          <br></br>
          <br></br>
          <strong>11. INSPECTION. </strong> Buyer shall inspect all items upon arrival and give written notice to Arco Innovation within ten (10) days of arrival of any claim for shortage or nonconformance with these terms. Failure to give such notice, Buyer waives all claims for such shortage or nonconformance, and Buyer shall be bound to accept and pay for all items in accordance with these terms.
          <br></br>
          <br></br>
          <strong>12. RETURNS. </strong> OUR RETURN POLICY IS IN ADDITION TO ANY STATUTORY RIGHTS YOU MAY HAVE UNDER LAW. WE WILL ACCEPT ANY ITEMS RETURNED IN ACCORDANCE WITH OUR RETURN POLICY DESCRIBED ON OUR RETURN POLICY AND LIMITED WARRANTY TERMS. WE MAY DENY ANY RETURN IF IT FAILS TO MEET OUR RETURN CRITERIA DESCRIBED IN OUR RETURN POLICY. FOR ADDITIONAL INFORMATION ON OUR RETURN PROCESS AND POLICY, PLEASE VISIT OUR RETURN POLICY AND LIMITED WARRANTY TERMS.
          <br></br>
          <br></br>
          No product may be returned without Arco Innovation's prior written approval. Transportation charges are to be prepaid by Buyer.
          <br></br>
          <br></br>
          <strong>13. PAYMENT.</strong> The Site gives you different payment options using a PCI compliant Third-Party gateway. We reserve the right to change our payment options at any time and for any reason. Payment must be received by us before our acceptance of an order. When you provide credit card information or other information necessary to facilitate payment to us or our vendors, you represent to us that (i) you are the authorized user of the credit card that is used to pay for the Products and Services, (ii) the credit card information you supply to us is true, correct and complete, (iii) charges incurred by you will be honored by your credit card company, and (iv) you will pay charges incurred by you at the posted prices, including shipping and handling charges and all applicable taxes, if any, regardless of the amount quoted on the Site at the time of your order.
          <br></br>
          <br></br>
          In the event legal action is necessary to collect on balances due, you will reimburse us and our vendors or agents for all expenses incurred to recover sums due, including attorneys’ fees and other legal expenses. Credits or refunds will be made to the same method of payment and account used to place the order.
          <br></br>
          <br></br>
          <strong>14. TAXES.</strong> Buyer shall pay all sales, use, excise, value added or other similar taxes that may levied, assessed or otherwise become due on account of items to be delivered hereunder, unless proof of exemption is furnished to Arco Innovation. If applicable, a separate charge for taxes will be itemized on the check-out page and invoice.
          <br></br>
          <br></br>
          <strong>International Customers:</strong> Arco Innovation is not responsible for any brokerage, customs fees, or country taxes. The actual value of all orders is indicated on the invoice, and restatements using lower values are not permitted.
          <br></br>
          <br></br>
          <strong>15. REPAIRS, ALTERATIONS AND MODIFICATIONS.</strong>  If Buyer requests that Arco Innovation repair any Arco Innovation product not covered by the warranty provided in Section 16 below, such repair charges shall be at the Buyer’s expense unless specifically authorized in writing by Arco Innovation. Any alterations or modifications to the Arco Innovation product by Buyer or Buyer’s agents will void the warranty and render the products nonreturnable by Buyer.
          <br></br>
          <br></br>
          <strong>16. WARRANTY. </strong> Arco Innovation warrants that Arco Innovation products will meet the applicable Arco Innovation product specification at the time Arco Innovation ships the product. If Arco Innovation product packaging includes a specific warranty period, Arco Innovation warrants that products will conform to those written specifications set forth in Arco Innovation’s product literature and/or on Arco Innovation packaging and labeling materials for such products for the time period specified in such literature and materials; provided, however, that products are installed, operated, and maintained in accordance with written instructions furnished by Arco Innovation. Arco Innovation shall have no obligation under this warranty with respect to Arco Innovation products that have been incorrectly installed, improperly maintained, or modified or damaged through misuse, abuse, accident, neglect, or mishandling by Buyer or third parties. Arco Innovation's sole liability and Buyer’s sole remedy under this warranty shall be, at Arco Innovation’s sole option, for Arco Innovation to either repair or replace any product which, having been returned, transportation charges prepaid, has been inspected and determined by Arco Innovation to be defective, or to refund the purchase price paid by Buyer for such defective product. All warranty claims must be made in writing to Arco Innovation prior to the end of the applicable warranty time period.
          <br></br>
          <br></br>
          THE ABOVE WARRANTY IS IN LIEU OF ANY OTHER WARRANTY AND CONDITION, AND ALL OTHER WARRANTIES OR CONDITIONS ARE HEREBY DISCLAIMED, WHETHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTY OR CONDITION OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR ANY IMPLIED WARRANTY OR CONDITION ARISING FROM A COURSE OF DEALING OR PERFORMANCE OR USAGE OF TRADE.
          <br></br>
          <br></br>
          <strong>17. LIMITATION OF LIABILITY. </strong>Under no circumstances shall Arco Innovation be liable to Buyer or any third party for any indirect, special, incidental or consequential damages, including but not limited to loss of profits, business, or revenue, arising out of or as a result of Arco Innovation’s provision of products hereunder, including but not limited to product defects or product failure, even if Arco Innovation has been advised of the possibility of such damages. Arco Innovation’s liability for direct damages will in no event exceed the price paid by the Buyer for the Arco Innovation product(s) giving rise to Buyer’s claim for damages.
          <br></br>
          <br></br>
          <strong>18. COMPLIANCE WITH LAWS.</strong> Buyer represents, warrants, and covenants that buyer and its affiliates, owners, officers, directors, employees, agents, subcontractors, consultants, and representatives (collectively referred to as “Representatives”) will perform all of buyer’s obligations under these terms in compliance with all local, state, provincial, national, and international statutes, rulings, regulations, ordinances, and governmental directives, including, without limitation, those pertaining to anti-bribery (example: U.S. Foreign Corrupt Practices Act, U.K. Bribery Act), money laundering, competition, regulation of trade, the environment, transportation, safety, health, and employment (collectively referred to as "Laws") that apply to Arco Innovation, buyer, either party’s business, and the Arco Innovation products and/or services to which these terms relate. Buyer further represents and warrants that neither it nor its Representatives will take any action that might cause Arco Innovation to violate any Law. Buyer will advise Arco Innovation immediately if it learns, or has any reason to know, of (i) any violation of any Law by buyer or its Representatives that occurred or may have occurred in performing buyer’s obligations under these terms or (ii) any failure of buyer or any of its Representatives to comply with buyer’s obligations under this section.
          <br></br>
          <br></br>
          <strong>19. TRADEMARKS. </strong> Buyer acknowledges and agrees that “Arco” and any other trademarks of Arco Innovation (“Arco Marks”) and associated goodwill are Arco Innovation’s exclusive property. By selling products to Buyer, Arco Innovation does not grant Buyer any right to use Arco Marks in connection with the resale of the products unless expressly permitted in writing by Arco Innovation. Buyer will not, at any time, contest the validity of any Arco Mark, claim any rights in any Arco Mark or do anything which, in Arco Innovation's opinion, might disparage, confuse or lessen the significance of any Arco Mark.
          <br></br>
          <br></br>
          <strong>20. BUYER WARRANTIES.</strong> If Buyer makes any express or implied warranties regarding Arco Innovation products (or regarding Buyer products into which Arco Innovation products are incorporated) that differ from the warranties offered by Arco Innovation as set forth in Section 16 above, Buyer shall assume full responsibility for and indemnify, defend, and hold harmless Arco Innovation, and Arco Innovation’s affiliates, successors, assigns, officers, directors, employees and agents from any liability, loss, damage, claim, lien, judgment, and cost, including but not limited to reasonable attorneys’ fees and other litigation expenses, related to any such warranties and/or remedies offered by Buyer.
          <br></br>
          <br></br>
          <strong>21. INDEMNIFICATION. </strong> Buyer indemnifies Arco Innovation, its affiliates, and their employees and agents (the "Indemnified Parties") for, and will defend them against, any claim, personal injury, sickness, death, liability, loss, damage, lien, judgment, and cost, including reasonable attorneys’ fees and other litigation expenses (collectively referred to as “Loss”) in any way related to (a) any alleged express or implied warranty by Buyer or any party to whom Buyer sells, gives or otherwise transfers Products or a product of Buyer’s into which Products are incorporated that differs from Arco Innovation’s published warranties and (b) any claim related to the use, packaging, labeling, handling, testing, marketing or sale of Products or a product of Buyer’s into which Products are incorporated, except where Products are shown not to meet the then-current published specification.
          <br></br>
          <br></br>
          <strong>22. GENERAL. </strong> (a) Buyer will offer nothing of value to any government official on behalf of Arco Innovation. (b) Arco Innovation is not responsible for typographical errors made in any of its publications or for clerical errors made in preparation of quotations, sales orders, or acknowledgments. All such errors are subject to correction by Arco Innovation. (c) These Terms and Conditions shall be governed by the laws of the Province of Ontario without regard to its conflicts of law provisions. Buyer submits to the exclusive personal and subject matter jurisdiction of the Ontario courts to resolve any disputes arising hereunder. (d) These Terms and Conditions, along with Arco Innovation’s quotation or proposal, contain the parties’ entire understanding on this subject, superseding any prior written or oral agreements, statements, communications or understandings between the parties relating to this subject matter. The 1980 United Nations Convention on Contracts for the International Sales of Goods will not govern these Terms and Conditions. A party's failure to require strict performance of these Terms and Conditions in one or more circumstances will not be a waiver of that party's right subsequently to require strict performance under these Terms and Conditions. The parties hereto confirm that it is their express wish that these Terms and Conditions as well as all other documents relating to these Terms and Conditions, including notices, be drawn up in English only. Les parties aux présentes confirment que c'est leur volonté que la présente convention de même que tous les documents, y compris les avis, s’y rattachant, soient redigés en anglais seulement.
          <br></br>
          <br></br>
          <strong></strong>
          <br></br>
          <br></br>

          If you have any questions about thes terms, please email us at <a href="mailto:support@arcoinnovation.com">support@arcoinnovation.com</a> 
          </FeatureText>
        </FeatureItem>             
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const mystyle = {
    width: "150px",
    height: "10px",
    display: "inline-block",
}

const mystyleBox = {
    padding: '10px',
}

const StyledContainer = styled(Container)`
margin: 80px auto;
}
`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  text-align: center;
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: justify;
`
const JumpTo = styled.div`
  padding: 10px;
  display: flex;
  background-color: rgb(211,211,211);
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
